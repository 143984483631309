import {Component, HostListener, Optional, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
     selector: 'silk-app',
     template:'<router-outlet></router-outlet>',
    encapsulation: ViewEncapsulation.None
})
export class SilkAppComponent {

   constructor(private router:Router, public translate: TranslateService) {
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang('en');

      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

      document.addEventListener('contextmenu', function(e) {
         e.preventDefault();
       });

   }

   @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    console.log(e)
    if (e.key === 'F12') {
       alert('This function has been disabled');
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'I') {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'C') {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'J') {
      return false;
    }
    if (e.ctrlKey && e.key === 'U') {
      return false;
    }
    return true;
  }

   ngOnInit() {
   }
    

}
