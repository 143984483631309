import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS : Menu[] = [
 {
    state: 'home',
    name: 'HOME',
    type: 'sub',
    icon: 'home',
    children: [
      {state: 'carousel', name: 'Carousel'},
      {state: 'call-to-action', name: 'Call To Action'},
      {state: 'banner', name: 'Banner'},
      {state: 'banner-product', name: 'Banner Product'},
      {state: 'banner-solution', name: 'Banner Solution'}
    ]
  },
  {
    state: 'products',
    name: 'Products',
    type: 'sub',
    icon: 'folder',
    children: [
      {state: 'trimble', name: 'Trimble'},
      {state: 'radanSystem', name: 'Radar System'}
    ]
  },
  {
    state: 'solutions',
    name: 'Solutions',
    type: 'link',
    icon: 'format_align_justify'
  }, {
    state: 'services',
    name: 'Services',
    type: 'link',
    icon: 'local_activity'
  }, {
    state: 'brochures',
    name: 'Brochures',
    type: 'link',
    icon: 'format_align_justify'
  }, {
    state: 'about',
    name: 'About',
    type: 'sub',
    icon: 'person',
    children: [
      {state: 'about-us', name: 'About Us'},
      {state: 'customers', name: 'Customers'},
      {state: 'news', name: 'News'},
      {state: 'events', name: 'Events'},
      {state: 'training', name: 'Trainings'},
    ]
  },
   {
    state: 'contact-us',
    name: 'Contact Us',
    type: 'sub',
    icon: 'phone',
    children: [
      {state: 'contacts', name: 'Vts Contact Details'},
      {state: 'map-location', name: 'Vts Map Location'},
    ]
  },
  /* {
    state: 'vts-mail',
    name: 'VTS Mails',
    type: 'link',
    icon: 'mail'
  }, */
  {
    state: 'setting',
    name: 'Settings',
    type: 'sub',
    icon: 'settings',
    children: [
      {state: 'footer-location', name: 'Footer Location'},
      {state: 'footer-detail', name: 'Footer Detail'},
      {state: 'privacy-policy', name: 'Privacy Policy'}
    ]
  }, {
    state: 'upload-photos',
    name: 'Upload Photos',
    type: 'link',
    icon: 'photo',
  }, {
    state: 'user-pages',
    name: 'USERS',
    type: 'link',
    icon: 'person',
  },  {
    state: 'logout',
    name: 'Logout',
    type: 'link',
    icon: 'close',
  }/* ,
  {
    state: 'emails',
    name: 'Emails',
    type: 'link',
    icon: 'mail'
  },
  {
    state: 'chat',
    name: 'CHAT',
    type: 'link',
    icon: 'chat_bubble_outline'
  },
  {
    state: 'features',
    name: 'FEATURES',
    type: 'sub',
    icon: 'library_books',
    children: [
      {state: 'colorpicker', name: 'COLOR PICKER'},
      {state: 'cropper', name: 'CROPPER'},
      {state: 'dragula', name: 'DRAGULA'},
      {state: 'sortable', name: 'SORTABLE'}
    ]
  },
  {
    state: 'components',
    name: 'COMPONENTS',
    type: 'sub',
    icon: 'local_activity',
    children: [
      {state: 'accordion', name: 'ACCORDION'},
      {state: 'alerts', name: 'ALERTS'},
      {state: 'buttons', name: 'BUTTONS'},
      {state: 'carousel', name: 'CAROUSEL'},
      {state: 'collapse', name: 'COLLAPSE'},
      {state: 'datepicker', name: 'DATEPICKER'},
      {state: 'dropdown', name: 'DROPDOWN'},
      {state: 'modal', name: 'MODAL'},
      {state: 'pagination', name: 'PAGINATION'},
      {state: 'popover', name: 'POPOVER'},
      {state: 'progressbar', name: 'PROGRESS BAR'},
      {state: 'rating', name: 'RATING'},
      {state: 'tabs', name: 'TABS'},
      {state: 'timepicker', name: 'TIMEPICKER'},
    ]
  },
  {
    state: 'icons',
    name: 'ICONS',
    type: 'sub',
    icon: 'center_focus_weak',
    children: [
      {state: 'linea', name: 'LINEA'},
      {state: 'font-awesome', name: 'FONT AWESOME'},
      {state: 'simple-line-icons', name: 'SIMPLE LINE ICONS'},
      {state: 'material-icons', name: 'MATERIAL ICONS'},
    ]
  },
  {
    state: 'cards',
    name: 'CARDS',
    type: 'sub',
    icon: 'grid_on',
    children: [
      {state: 'basic-cards', name: 'BASIC CARDS'},
      {state: 'advance-cards', name: 'ADVANCE CARDS'}
    ]
  },
  {
    state: 'forms',
    name: 'FORMS',
    type: 'sub',
    icon: 'format_align_justify',
    children: [
      {state: 'form-wizard', name: 'FORM CONTROLS'},
      {state: 'form-validation', name: 'FORM VALIDATION'},
      {state: 'form-upload', name: 'UPLOAD'},
      {state: 'form-tree', name: 'TREE'}
    ]
  },
  {
    state: 'tables',
    name: 'TABLES',
    type: 'sub',
    icon: 'grid_on',
    children: [
      {state: 'basic-tables', name: 'BASIC TABLES'},
      {state: 'responsive-tables', name: 'RESPONSIVE TABLES'}
    ]
  },
  {
    state: 'data-tables',
    name: 'DATA TABLES',
    type: 'sub',
    icon: 'format_line_spacing',
    children: [
      {state: 'selection', name: 'SELECTION'},
      {state: 'pinning', name: 'PINNING'},
      {state: 'sorting', name: 'SORTING'},
      {state: 'paging', name: 'PAGING'},
      {state: 'editing', name: 'EDITING'},
      {state: 'filter', name: 'FILTER'}
    ]
  },
  {
    state: 'chart',
    name: 'CHARTS',
    type: 'sub',
    icon: 'insert_chart',
    children: [
      {state: 'bar-charts', name: 'BAR'},
      {state: 'pie-charts', name: 'PIE'},
      {state: 'line-charts', name: 'LINE'},
      {state: 'mixed-charts', name: 'MIXED'},
      {state: 'ng2-charts', name: 'NG2 CHARTS'},
    ]
  },
    {
    state: 'maps',
    name: 'MAPS',
    type: 'sub',
    icon: 'map',
    children: [
      {state: 'googlemap', name: 'GOOGLE MAP'},
      {state: 'leafletmap', name: 'LEAFLET MAP'}
    ]
  },
    {
    state: 'pages',
    name: 'PAGES',
    type: 'sub',
    icon: 'web',
    children: [
      {state: 'about', name: 'ABOUT'},
      {state: 'contact', name: 'CONTACT'},
      {state: 'timeline', name: 'TIMELINE'},
      {state: 'pricing', name: 'PRICING'},
      {state: 'blank', name: 'BLANK'},
    ]
  },
  {
    state: 'authentication',
    name: 'AUTHENTICATION',
    type: 'sub',
    icon: 'security',
    children: [
      {state: 'login', name: 'LOGIN'},
      {state: 'register', name: 'REGISTER'},
      {state: 'forgot-password', name: 'FORGOT'},
      {state: 'lockscreen', name: 'LOCKSCREEN'}
    ]
  },
  {
    state: 'error',
    name: 'ERROR',
    type: 'sub',
    icon: 'error_outline',
    children: [
      {state: '404', name: '404'},
      {state: '503', name: '503'}
    ]
  },
  {
    state: 'calendar',
    name: 'CALENDAR',
    type: 'link',
    icon: 'date_range'
  },
  {
    state: 'media',
    name: 'MEDIA',
    type: 'sub',
    icon: 'perm_media',
    children: [
      {state: 'grid', name: 'GRID'},
      {state: 'list', name: 'LIST'}
    ]
  },
  {
    state: 'editor',
    name: 'EDITOR',
    type: 'sub',
    icon: 'format_shapes',
    children: [
      {state: 'ace-editor', name: 'ACE EDITOR'},
      {state: 'ckeditor', name: 'CKEDITOR'},
      {state: 'wysiwyg', name: 'WYSIWYG EDITOR'}
    ]
  } */

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu: Menu) {
    MENUITEMS.push(menu);
  }
}
