import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';

import { MainComponent } from './main/main.component';
import { AuthLayoutComponent } from './auth/auth-layout.component';
import { MapLocationComponent } from './contact-us/map-location/map-location.component';
import { CustomersComponent } from './subcontent/customers/customers.component';

export const AppRoutes: Routes = [{
  path: '',
  redirectTo: 'authentication',
  pathMatch: 'full',
},  { path: 'logout',
redirectTo: 'authentication',
pathMatch: 'full',
},
 {
  path: '',
  component: MainComponent,
  children: [{
    path: 'products',
    loadChildren: './products/products.module#ProductsModule'
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'solutions',
    loadChildren: './subcontent/listsubcontent/listsubcontent.module#ListsubcontentModule',
    data: {pageCategory: 'Solution'}
  }]
},  {
  path: '',
  component: MainComponent,
  children: [{
    path: 'about/news',
    loadChildren: './subcontent/news/news.module#NewsModule'
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'about/events',
    loadChildren: './subcontent/events/events.module#EventsModule',
    data: {pageCategory: 'Event'}
  }]
}, /* {
  path: '',
  component: MainComponent,
  children: [{
    path: 'about/training',
    loadChildren: './subcontent/training/training.module#TrainingModule',
    data: {pageCategory: 'Trainings'}
  }]
}, */ {
  path: '',
  component: MainComponent,
  children: [{
    path: 'about/customers',
    loadChildren: './subcontent/customers/customers.module#CustomersModule',
    data: {pageCategory: 'Customers'}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'services',
    loadChildren: './subcontent/listsubcontent/listsubcontent.module#ListsubcontentModule',
    data: {pageCategory: 'Service'}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'brochures',
    loadChildren: './brochure/brochure.module#BrochureModule',
    data: {pageCategory: 'Brochures'}
  }]
} , {
  path: '',
  component: MainComponent,
  children: [{
    path: 'upload-photos',
    loadChildren: './upload-photos/upload-photos.module#UploadPhotosModule',
    data: {pageCategory: 'Upload Photos'}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'about/about-us',
    loadChildren: './subcontent/listsubcontent/listsubcontent.module#ListsubcontentModule',
    data: {pageCategory: 'About Us'}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'about/training',
    loadChildren: './subcontent/listsubcontent/listsubcontent.module#ListsubcontentModule',
    data: {pageCategory: 'Training'}
  }]
},  {
  path: '',
  component: MainComponent,
  children: [{
    path: 'contact-us/contacts',
    loadChildren: './contact-us/contact-us.module#ContactUsModule',
    data: {pageCategory: 'Contact Us'}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'contact-us/map-location',
    loadChildren: './contact-us/map-location/map-location.module#MapLocationModule',
    data: {pageCategory: 'VTS Map Location'}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'vts-mail',
    loadChildren: './vts-mail/vts-mail.module#VtsMailModule',
    data: {pageCategory: 'VTS Mail'}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'content/addcontent',
    loadChildren: './subcontent/addsubcontent/addsubcontent.module#AddsubcontentModule',
    data: {pageCategory: null}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'content/subcontent',
    loadChildren: './subcontent/addsubcontent/addsubcontent.module#AddsubcontentModule',
    data: {pageCategory: null, id: null}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'user-pages',
    loadChildren: './user-pages/users.module#UsersModule',
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'setting/footer-location',
    loadChildren: './footer/footer.module#FooterModule',
    data: {pageCategory: null}
  }]
}, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'setting/footer-detail',
    loadChildren: './footer/footer-detail/footer-detail.module#FooterDetailModule',
    data: {pageCategory: null}
  }]
}
, {
  path: '',
  component: MainComponent,
  children: [{
    path: 'setting/privacy-policy',
    loadChildren: './footer/privacy-policy/privacy-policy.module#PrivacyPolicyModule',
    data: {pageCategory: null}
  }]
}
,
{
  path: '',
  component: MainComponent,
  children: [{
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'home/carousel',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
  }, {
    path: 'home/banner-product',
    loadChildren: './dashboard/banner/banner.module#BannerModule',
    data: {pageType: 'Product'}
  }, {
    path: 'home/banner-solution',
    loadChildren: './dashboard/banner/banner.module#BannerModule',
    data: {pageType: 'Solution'}
  }, {
    path: 'home/call-to-action',
    loadChildren: './dashboard/call-to-action/call-to-action.module#CallToActionModule',
    data: {pageType: 'Call To Action'}
  }, {
    path: 'home/banner',
    loadChildren: './dashboard/home-banner/home-banner.module#HomeBannerModule',
    data: {pageType: 'Homepage Banner'}
  }, {
    path: 'emails',
    loadChildren: './emails/emails.module#EmailsModule'
  }, {
    path: 'chat',
    loadChildren: './chat/chat.module#ChatModule'
  }, {
    path: 'features',
    loadChildren: './features/features.module#FeaturesModule'
  }, {
    path: 'components',
    loadChildren: './components/components.module#ComponentsModule'
  }, {
    path: 'icons',
    loadChildren: './icons/icons.module#IconsModule'
  }, {
    path: 'cards',
    loadChildren: './cards/cards.module#CardsModule'
  }, {
    path: 'forms',
    loadChildren: './forms/forms.module#FormsDemoModule'
  }, {
    path: 'tables',
    loadChildren: './tables/tables.module#TablesDemoModule'
  }, {
    path: 'data-tables',
    loadChildren: './data-tables/datatables.module#DataTablesDemoModule'
  }, {
    path: 'chart',
    loadChildren: './chart/charts.module#ChartsDemoModule'
  }, {
    path: 'maps',
    loadChildren: './maps/maps.module#MapsDemoModule'
  }, {
    path: 'pages',
    loadChildren: './custom-pages/pages.module#PagesDemoModule'
  }, {
    path: 'user-pages',
    loadChildren: './user-pages/users.module#UsersModule'
  }, {
    path: 'calendar',
    loadChildren: './calendar/calendar.module#CalendarDemoModule'
  }, {
    path: 'media',
    loadChildren: './media/media.module#MediaModule'
  }, {
    path: 'editor',
    loadChildren: './editor/editor.module#EditorDemoModule'
  }]
  }
, {
  path: '',
  component: AuthLayoutComponent,
  children: [{
    path: 'authentication',
    loadChildren: './session/session.module#SessionModule'
  }, {
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  }]
}];

