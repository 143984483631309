import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/service/api.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ms-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  rows = [];
  pageCategory = '';
  columns = [
    { prop: 'customers' },
    { name: 'title' },
    { name: 'banner' },
    { name: 'link' },
    { name: 'status' },
    { name: 'lastModifiedDate' }
    ];
  contentBanners = [];
  constructor(public restApi: ApiService,
    private pageTitleService: PageTitleService,
    private router: Router,
    private route: ActivatedRoute) {

      this.route.data.subscribe(params => {
        this.pageCategory = params['pageCategory'];
        this.getList();
      });
  }

  ngOnInit() {
    this.pageTitleService.setTitle(this.pageCategory);
  }

  getList() {
    this.restApi.getList('customers').subscribe((res) => {
      this.rows = res;
      console.log('Customers : ', res);
    });
  }

  viewContent(uuid: string ) {
    console.log('UUID {} : ' + uuid);
    this.router.navigate(['/about/customers/addCustomer'], { queryParams: { id: uuid, pageCategory: this.pageCategory} });
  }

  addCustomer() {
    this.router.navigate(['/about/customers/addCustomer'], { queryParams: {pageCategory: this.pageCategory} });
  }
}
